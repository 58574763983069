
export const Menu = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M24 9.5C24 10.3284 23.3284 11 22.5 11H9.5C8.67157 11 8 10.3284 8 9.5C8 8.67157 8.67157 8 9.5 8H22.5C23.3284 8 24 8.67157 24 9.5Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 15.5C24 16.3284 23.3284 17 22.5 17H9.5C8.67157 17 8 16.3284 8 15.5C8 14.6716 8.67157 14 9.5 14H22.5C23.3284 14 24 14.6716 24 15.5Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M24 21.5C24 22.3284 23.3284 23 22.5 23H9.5C8.67157 23 8 22.3284 8 21.5C8 20.6716 8.67157 20 9.5 20H22.5C23.3284 20 24 20.6716 24 21.5Z" fill="white" />
  </svg>
)

export const User = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.75" r="12" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M20.8796 17.1594C20.4822 17.5371 20.0669 17.8851 19.6339 18.1989C19.5204 18.2845 19.2889 18.4465 19.2866 18.4402C17.2382 19.8187 14.8605 20.5098 12.4824 20.5098C9.46455 20.5098 6.44896 19.3969 4.1046 17.177L3.50049 17.7807C5.9818 20.2604 9.2411 21.5007 12.5009 21.5007C15.7606 21.5007 19.0199 20.2604 21.5012 17.7807L20.8796 17.1594ZM12.5105 12.5044C14.9986 12.5044 17.0154 10.4889 17.0154 8.00244C17.0154 5.51601 14.9986 3.50049 12.5105 3.50049C10.0225 3.50049 8.00562 5.51601 8.00562 8.00244C8.00562 10.4889 10.0225 12.5044 12.5105 12.5044ZM18.8934 17.6178C17.6203 15.3106 15.1562 13.7961 12.4451 13.7961C9.75116 13.7961 7.29823 15.2957 6.01929 17.5809C9.86874 20.2709 15.0318 20.283 18.8934 17.6178Z" fill="black" />
  </svg>
)

export const Upload = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1996 15.2001V8.6801L9.11961 10.7601L7.99961 9.6001L11.9996 5.6001L15.9996 9.6001L14.8796 10.7601L12.7996 8.6801V15.2001H11.1996ZM7.19961 18.4001C6.75961 18.4001 6.38308 18.2436 6.07001 17.9305C5.75694 17.6174 5.60014 17.2406 5.59961 16.8001V14.4001H7.19961V16.8001H16.7996V14.4001H18.3996V16.8001C18.3996 17.2401 18.2431 17.6169 17.93 17.9305C17.6169 18.2441 17.2401 18.4006 16.7996 18.4001H7.19961Z" fill="black" />
  </svg>
)

export const RecordOff = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 0C43.4532 0 56 12.5468 56 28C56 43.4532 43.4532 56 28 56C12.5468 56 0 43.4532 0 28C0 12.5468 12.5468 0 28 0Z" fill="#E8E8E8" />
    <path d="M28 6C40.1418 6 50 15.8582 50 28C50 40.1418 40.1418 50 28 50C15.8582 50 6 40.1418 6 28C6 15.8582 15.8582 6 28 6Z" fill="#C6C6C6" />
    <path d="M34.0571 27.2857C34.0571 30.7143 31.1543 33.1143 28 33.1143C24.8457 33.1143 21.9429 30.7143 21.9429 27.2857H20C20 31.1829 23.1086 34.4057 26.8571 34.9657V38.7143H29.1429V34.9657C32.8914 34.4171 36 31.1943 36 27.2857H34.0571ZM28 30.7143C29.8971 30.7143 31.4171 29.1829 31.4171 27.2857L31.4286 20.4286C31.4286 18.5314 29.8971 17 28 17C26.1029 17 24.5714 18.5314 24.5714 20.4286V27.2857C24.5714 29.1829 26.1029 30.7143 28 30.7143Z" fill="white" />
  </svg>
)

export const RecordOn = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 0C43.4532 0 56 12.5468 56 28C56 43.4532 43.4532 56 28 56C12.5468 56 0 43.4532 0 28C0 12.5468 12.5468 0 28 0Z" fill="#C5162E" fillOpacity="0.15" />
    <path d="M28 6C40.1418 6 50 15.8582 50 28C50 40.1418 40.1418 50 28 50C15.8582 50 6 40.1418 6 28C6 15.8582 15.8582 6 28 6Z" fill="#C5162E" />
    <path d="M34.0571 27.2857C34.0571 30.7143 31.1543 33.1143 28 33.1143C24.8457 33.1143 21.9429 30.7143 21.9429 27.2857H20C20 31.1829 23.1086 34.4057 26.8571 34.9657V38.7143H29.1429V34.9657C32.8914 34.4171 36 31.1943 36 27.2857H34.0571ZM28 30.7143C29.8971 30.7143 31.4171 29.1829 31.4171 27.2857L31.4286 20.4286C31.4286 18.5314 29.8971 17 28 17C26.1029 17 24.5714 18.5314 24.5714 20.4286V27.2857C24.5714 29.1829 26.1029 30.7143 28 30.7143Z" fill="white" />
  </svg>
)

export const StopRecord = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 0C43.4532 0 56 12.5468 56 28C56 43.4532 43.4532 56 28 56C12.5468 56 0 43.4532 0 28C0 12.5468 12.5468 0 28 0Z" fill="#C5162E" fillOpacity="0.15" />
    <path d="M28 6C40.1418 6 50 15.8582 50 28C50 40.1418 40.1418 50 28 50C15.8582 50 6 40.1418 6 28C6 15.8582 15.8582 6 28 6Z" fill="#C5162E" />
    <rect x="20" y="20" width="16" height="16" rx="2" fill="white" />
  </svg>
)

export const RecordCircle = () => (
  <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="6" cy="6.5" r="6" fill="#CC2936" />
  </svg>
)

export const RecordSend = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28 0C43.4532 0 56 12.5468 56 28C56 43.4532 43.4532 56 28 56C12.5468 56 0 43.4532 0 28C0 12.5468 12.5468 0 28 0Z" fill="#3659E3" fillOpacity="0.2" />
    <path d="M28 6C40.1418 6 50 15.8582 50 28C50 40.1418 40.1418 50 28 50C15.8582 50 6 40.1418 6 28C6 15.8582 15.8582 6 28 6Z" fill="#3659E3" />
    <path d="M21.25 36.6667L40.75 28L21.25 19.3333V27.037L34.9 28L21.25 28.963L21.25 36.6667Z" fill="white" />
  </svg>
)

export const FileSelected = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_691_1982)">
      <circle cx="10" cy="10" r="10" fill="black" />
      <path d="M9.09049 13.9907L5.375 10.2752L6.30387 9.34636L9.09049 12.133L15.0711 6.15234L16 7.08122L9.09049 13.9907Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_691_1982">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const Remove = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.51232 7.05607L7.08375 8.47299L10.6259 12.0444L7.05431 15.5868L8.47119 17.0154L12.0427 13.473L15.5851 17.0446L17.0136 15.6276L13.4713 12.056L17.0426 8.51389L15.6257 7.08529L12.0544 10.6275L8.51232 7.05607Z" fill="black" />
  </svg>
)

