import styled from "styled-components";

export const StyledHeader = styled.header`
  background-color: var(--header-background);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  & > nav {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    span.sep {
      flex: 1;
    }
  }
  &.container > nav {
    padding: 16px 0;
  }
  &.container {
    padding-top: 20px;
  }
  .btn-nav {
    all: unset;
    background-color: #1e1e1e;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 4px 20px 6px 20px;
    border-radius: 20px;
    cursor: pointer;
  }
`;


export const HomePageContent = styled.div`
  text-align: center;
  padding-top: 56px;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  & > form, & > div {
    max-width: 390px;
    width: 90%;
    padding: 0 16px;
    * {
      margin: 0;
      padding: 0;
    }
    h1 {
      color: var(--dark-color);
      text-align: center;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }
    p.subtitle {
      margin-bottom: 40px;
    }
    p.interview-duration {
      margin-bottom: 12px;
    }
    .interview-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #39364F;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 24px;
      gap: 16px;
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }
    .form {
      display: flex;
      flex-direction: column;
      gap: 16px;
      text-align: left;

      .form-title {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .form-footer {
        display: flex;
        align-items: center;
        gap: 24px;
        color: #000;
        justify-content: center;
        a {
          font-size: 14px;
          color: #000;
        }
      }
    }
    .ready-to-start-actions {
      display: flex;
      gap: 16px;
      align-items: center;
      justify-content: center;
    }
  }
`;


export const InterviewPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 165px);
  padding-top: 100px;
  .sep {
    flex: 1;
  }
  p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .timer {
    display: flex;
    padding: 12px 32px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 6px;
    background: #000;
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .timerrecording {
    display: flex;
    padding: 7px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: rgba(204, 41, 54, 0.10);
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 30px;
  }
  img {
    width: 250px;
    margin-top: 30px;
  }
`;

export const InterviewActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px !important;
  border-top: 2px solid #F4F4F4;
  background: #FFF;
  width: -webkit-fill-available;
  width: -moz-available;
  width: fill-available;
  button.btn-primary {
    position: absolute;
    right: 20px;
  }
`;
