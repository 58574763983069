/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Button } from "../components/formControl";
import Loader from "../components/loader";
import { InterviewActions, InterviewPageContainer } from "../components/slyledComponents";
import { useGlobalContext } from "../utils/globalContext";
import AudioPlayer from "../components/audioPlayer";
import RecordAudio, { RECORDING_STATUS } from "../components/recordAudio";
import { RecordCircle } from "../components/icons";
import { EndInterviewModal } from "../components/modal";
import { PAGES } from "../utils/constants";

export const INTERVIEW_SCREENS = {
  INIT: 'INIT',
  READY: 'READY',
  END: 'END',
  WAITING_FOR_USER_ANSWER: 'WAITING_FOR_USER_ANSWER',
  USER_AUDIO_RECORDING: 'USER_AUDIO_RECORDING',
  USER_AUDIO_SEND: 'USER_AUDIO_SEND',
  USER_AUDIO_SENDING: 'USER_AUDIO_SENDING',
  ERROR_MEDIA_ACCESS: 'ERROR_MEDIA_ACCESS',
  INTERVIEWER_SPEAKS: 'INTERVIEWER_SPEAKS',
  INTERVIEW_END_TIME: 'INTERVIEW_END_TIME',
  INTERVIEW_END_USER: 'INTERVIEW_END_USER',
}

function InterviewPage() {
  const { loading, thread, sendMessage, endInterview, interviewInfos, endedInterview } = useGlobalContext();
  const [screen, setScreen] = useState(INTERVIEW_SCREENS.INIT);
  const [time, setTime] = useState(interviewInfos.duration * 60);
  const [recordTime, setRecordTime] = useState(0);
  const [threadId, setThreadId] = useState(null);
  const [openaiAssistantId, setOpenaiAssistantId] = useState(null);
  const [recordTimeEnabled, setRecordTimeEnabled] = useState(false);
  const [showConfirmEndModal, setShowConfirmEndModal] = useState(false);
  const [timer, setTimer] = useState(false);
  const { user } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    let t = null;
    if (timer) {
      t = setInterval(() => {
        setTime((v) => {
          if (!v) {
            endInterview('time');
            setScreen(INTERVIEW_SCREENS.INTERVIEW_END_TIME);
          }
          return Math.max(0, v - 1);
        });
      }, 1000);
    }
    return () => {
      if (t) clearInterval(t);
    }
  }, [timer]);

  useEffect(() => {
    if ([INTERVIEW_SCREENS.INTERVIEW_END_TIME, INTERVIEW_SCREENS.INTERVIEW_END_USER].includes(screen) && timer) {
      setTimer(false);
    }
  }, [screen])

  useEffect(() => {
    let t = null;
    if (recordTimeEnabled) {
      t = setInterval(() => {
        setRecordTime((v) => {
          const newTime = Math.min(30 * 60, v + 1);
          if (newTime === 105) {
            alert('Keep your responses under 2-minute mark');
          }
          return newTime;
        });
      }, 1000);
    }
    return () => {
      if (t) clearInterval(t);
    }
  }, [recordTimeEnabled]);

  useEffect(() => {
    if (!loading) {
      if (thread.length) {
        setThreadId(thread[0].threadId);
        setOpenaiAssistantId(thread[0].openaiAssistantId)
        setScreen(INTERVIEW_SCREENS.READY);
        setTime(interviewInfos.duration * 60);
        setTimer(true);
      } else {
        navigate(PAGES.HOME.path);
      }
    }
  }, [loading]);

  const onUserRecordingStatusChange = useCallback((status, data) => {
    if (status === RECORDING_STATUS.START) {
      setRecordTime(0);
      setRecordTimeEnabled(true);
      setScreen(INTERVIEW_SCREENS.USER_AUDIO_RECORDING);
    } else if (status === RECORDING_STATUS.END) {
      setScreen(INTERVIEW_SCREENS.USER_AUDIO_SEND);
      setRecordTimeEnabled(false);
    } else if (status === RECORDING_STATUS.SEND) {
      setScreen(INTERVIEW_SCREENS.USER_AUDIO_SENDING);
      sendMessage({
        userId: user.id,
        threadId: threadId,
        openaiAssistantId,
        message: data,
      })
        .then(() => setScreen(INTERVIEW_SCREENS.READY))
        .catch(() => setScreen(INTERVIEW_SCREENS.WAITING_FOR_USER_ANSWER));
    }
  }, [sendMessage, threadId, user.id]);

  const audioEnd = () => {
    setScreen(INTERVIEW_SCREENS.WAITING_FOR_USER_ANSWER);
  }

  const audioStart = () => {
    setScreen(INTERVIEW_SCREENS.INTERVIEWER_SPEAKS);
  }

  if (screen === INTERVIEW_SCREENS.ERROR_MEDIA_ACCESS) {
    return (
      <InterviewPageContainer>
        <p>Please allow microphone access in your browser settings to proceed with the interview.</p>
        <Button onClick={() => window.location.reload()}>Try again</Button>
      </InterviewPageContainer>
    )
  }

  return (
    <InterviewPageContainer>
      {!loading && thread.length && timer ? <AudioPlayer audioBase64={thread[thread.length - 1].audio} onEnd={audioEnd} onStart={audioStart} /> : null}
      <Timer value={time} className="timer" />
      {screen === INTERVIEW_SCREENS.INTERVIEWER_SPEAKS && <img src="/images/anim.gif" alt="interviewer" />}
      {[INTERVIEW_SCREENS.INTERVIEW_END_USER, INTERVIEW_SCREENS.INTERVIEW_END_TIME].includes(screen) ? (
        <>
          <p style={{ margin: '90px 0 24px 0' }}>
            Congrats, your interview is completed! 🎉
          </p>
          <Button className="btn-primary" onClick={endedInterview?.onClick}>View debrief</Button>
        </>
      ) : null}
      <div className="sep" />
      {screen === INTERVIEW_SCREENS.INIT && <Loader />}
      {screen === INTERVIEW_SCREENS.USER_AUDIO_SENDING && <Loader />}
      {screen === INTERVIEW_SCREENS.USER_AUDIO_RECORDING && <Timer value={recordTime} className="timerrecording" />}
      {screen === INTERVIEW_SCREENS.USER_AUDIO_SEND && <Timer value={recordTime} label="recorded" className="timerrecording" />}
      {screen === INTERVIEW_SCREENS.INIT && <p>Your interview is about to start</p>}
      {screen === INTERVIEW_SCREENS.WAITING_FOR_USER_ANSWER && <p>Please record your response</p>}
      {screen === INTERVIEW_SCREENS.USER_AUDIO_SEND && <p>Please send your response</p>}
      {screen === INTERVIEW_SCREENS.USER_AUDIO_SENDING && <p>Interviewer is thinking</p>}
      <InterviewActions>
        <RecordAudio screen={screen} onStatusChange={onUserRecordingStatusChange} />
        {timer && <Button className="btn-primary" onClick={() => setShowConfirmEndModal(true)}>End interview</Button>}
      </InterviewActions>
      <EndInterviewModal
        show={showConfirmEndModal}
        onClose={() => setShowConfirmEndModal(false)}
        onConfirm={() => {
          endInterview('user');
          setScreen(INTERVIEW_SCREENS.INTERVIEW_END_USER);
          setShowConfirmEndModal(false)
        }}
        minutesLeft={Math.ceil(time / 60)}
      />
    </InterviewPageContainer>
  )
}

function Timer({ className, label, value }) {

  return (
    <div className={className}>
      {className === 'timerrecording' ? <RecordCircle /> : null}
      {`${Math.floor(value / 60).toString().padStart(2, '0')}:${(value % 60).toString().padStart(2, '0')}`}
      {label || ''}
    </div>
  )
}

export default InterviewPage;