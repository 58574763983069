import { useEffect } from "react";
import { Popover } from '@typeform/embed-react';
import { styled } from 'styled-components';
import { useNavigate } from "react-router-dom";
import { useGlobalContext } from "../utils/globalContext"
import { PAGES } from "../utils/constants";
import Loader from "../components/loader";

function DebriefPage() {
  const { debriefItem } = useGlobalContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!debriefItem) {
      navigate(PAGES.HISTORY.path);
    }
  }, [debriefItem])

  return (
    <DebriefPageWrapper>
      <h1>Interview debrief</h1>
      <div className="debrief-header">
        <div className="debrief-date">
          <b>{debriefItem.month}</b>
          <b>{debriefItem.day}</b>
        </div>
        <span />
        <div style={{ flex: 2 }}>
          <span>Role</span>
          <b>{debriefItem.role}</b>
        </div>
        <span />
        <div>
          <span>Duration</span>
          <b>{debriefItem.duration} min.</b>
        </div>
      </div>
      {debriefItem.loading ? (
        <div className="debriefItemLoader">
          <Loader />
        </div>
      ) : (
        <>
          {Object.entries(debriefItem.debrief || {}).map(([key, value]) => (
            <div key={key}>
              <h2>{key}</h2>
              <p>{value}</p>
            </div>
          ))}
          {/* <h2>Hire Recommendation</h2>
          <p>{debriefItem.debrief?.hire}</p>
          <h2>Went well</h2>
          <p>{debriefItem.debrief?.well}</p>
          <h2>Can be improved</h2>
          <p>{debriefItem.debrief?.improve}</p> */}
          <Popover
            id="l02PDkgE"
            tooltip="Hey 👋  How was your interview experience"
            buttonColor="#000000"
            className="typeform-popover"
          >
          </Popover>
        </>
      )}
    </DebriefPageWrapper>
  )
}


const DebriefPageWrapper = styled.div`
  max-width: 500px;
  margin: 50px auto;
  .debrief-header {
    border-radius: 8px;
    border: 1px solid #DBDAE3;
    padding: 16px;
    display: flex;
    & > span {
      width: 1px;
      background-color: #DBDAE3;
    }
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1;
    }
  }
  .debrief-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .debriefItemLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
  }
`;

export default DebriefPage
