import React, { useEffect, useState } from 'react';
import { SignedIn, SignedOut, SignInButton, UserButton } from "@clerk/clerk-react";
import Logo from './logo';
import { Menu } from './icons';
import { StyledHeader } from './slyledComponents';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Header = ({ landing }) => {
  return (
    <StyledHeader className={landing ? 'container' : ''}>
      <nav>
        <Link className='link' to='/'>
          <Logo className={landing ? 'landing' : ''} />
        </Link>
        <span className="sep" />
        {landing ? null : <MenuButton />}
        <SignedOut>
          <SignInButton>
            <button className='btn-nav'>
              Sign In
            </button>
          </SignInButton>
        </SignedOut>
        <SignedIn>
          <UserButton />
        </SignedIn>
      </nav>
    </StyledHeader>
  );
};

const MenuButton = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const closeMenu = (e) => {
      if (open && !e.target.closest('.menu-button')) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', closeMenu);

    return () => document.removeEventListener('mousedown', closeMenu);
  }, [open]);

  return (
    <>
      <button className='menu-button' style={{ all: 'unset' }} onClick={() => setOpen(v => !v)}>
        <Menu />
      </button>
      {open && (
        <MenuList className='menu-button'>
          <li>
            <Link to="/history">Interview history</Link>
          </li>
        </MenuList>
      )}
    </>
  )
}


const MenuList = styled.ul`
  position: absolute;
  top: 68px;
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  border: 1px solid #F8F7FA;
  background: #FFF;
  box-shadow: 0px 2px 5px 0px rgba(40, 44, 53, 0.15);
  & > li {
    padding: 16px;
    cursor: pointer;
    a {
      text-decoration: none;
      color: inherit;
    }
  }
`;


export default Header;
