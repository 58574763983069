import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ClerkProvider, RedirectToSignIn, SignedIn, SignedOut } from '@clerk/clerk-react'
import TagManager from 'react-gtm-module';
import Layout from './layout/layout';
import { GlobalProvider } from './utils/globalContext';
import { HomePage, InterviewPage, NotFoundPage, HistoryPage, DebriefPage } from './pages';
import { PAGES } from './utils/constants';
import './App.css';


function App() {

  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-T87N33G5' });
  }, []);

  return (
    <ClerkProvider publishableKey={process.env.REACT_APP_CLERK_PUBLISHABLE_KEY}>
      <BrowserRouter>
        <GlobalProvider>
          <SignedOut>
            <Routes>
              <Route path="/login" element={<RedirectToSignIn afterSignInUrl="/" />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </SignedOut>
          <SignedIn>
            <Routes>
              <Route element={<Layout />}>
                <Route path={PAGES.HOME.path} element={<HomePage />} />
                <Route path={PAGES.INTERVIEW.path} element={<InterviewPage />} />
                <Route path={PAGES.HISTORY.path} element={<HistoryPage />} />
                <Route path={PAGES.DEBRIEF.path} element={<DebriefPage />} />
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="*" element={<NotFoundPage />} />
              </Route>
            </Routes>
          </SignedIn>
        </GlobalProvider>
      </BrowserRouter>
    </ClerkProvider>
  );
}

 

export default App;
