import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from '@clerk/clerk-react';
import { Button, FormControl, UploadResume } from "../components/formControl";
import Plan from "../components/Plan";
import { PAGES } from "../utils/constants";
import { HomePageContent } from "../components/slyledComponents";
import { useGlobalContext } from "../utils/globalContext";

const HomePage = () => {
  const [showReadyToStart, setShowReadyToStart] = useState(false);
  const [role, setRole] = useState(''); // role
  const { user } = useUser();
  const navigate = useNavigate();
  const { startInterview, getUserInfos, userInfos, loading } = useGlobalContext();

  const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
  const isMobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));

  const handleStartInterview = () => {
    navigate(PAGES.INTERVIEW.path)
  }
  const { api } = useGlobalContext();

  useEffect(() => {
    getUserInfos();
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (userInfos?.totalRemainingInterviews === 0) {
      api.post('checkout', { url: window.location.href })
        .then(({ url }) => {
          window.location = url;
        });
    } else {
      const formData = new FormData(e.target);
      setShowReadyToStart(true);
      const formDataObj = Object.fromEntries(formData.entries());
      setRole(formDataObj.role); // role
      const reader = new FileReader();
      reader.onloadend = () => {
        const pdfBase64 = reader.result.split(',')[1];
        startInterview({
          userId: user.id,
          duration: +formDataObj.duration,
          // language: formDataObj.language,
          role: formDataObj.role,
          resume: pdfBase64,
          interviewStyle: formDataObj.interview,
          extraInformation: '',
        })
          .catch((error) => {
            setShowReadyToStart(false);
            console.error(error);
          });
      };
      reader.readAsDataURL(formDataObj.resume);
      const a = new Audio();
      a.play();
    }
  };

  return (
    <HomePageContent>
      {isMobile ? (
          <div className="mobile-warning">
          <img src="/images/Stock-image.svg" alt="Desktop placeholder" />
          <div style={{ fontSize: '20px' }}>For a best experience, we recommend accessing the app via Chrome browser on a laptop or desktop.</div>
        </div>
      ) : showReadyToStart ? (
        <div>
          <h1>You are interviewing for {role} role!</h1>
          <p className="subtitle">Your AI interviewing copilot is well-versed knows your background and role. Feel free to converse naturally and specify what you'd like to practice.</p>
          <div className="ready-to-start-actions">
            <Button onClick={() => setShowReadyToStart(false)} >Return</Button>
            <Button onClick={handleStartInterview} className="btn-primary">Start</Button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <h1>Welcome, {user?.firstName}!</h1>
          <p className="subtitle">Help us craft your interview experience.</p>
          <p className="interview-duration">Interview duration</p>
          <div className="interview-radio">
            <label><input type="radio" name="duration" value="15" defaultChecked /> 15 min</label>
            {userInfos?.totalInterviews > 1 ? <label><input type="radio" name="duration" value="30" /> 30 min</label> : null}
          </div>
          <div className="form">
            <p className="form-title">You're interviewing for:</p>
            <FormControl label="Role" placeholder="Product Designer" name="role" required />
            {/* <FormControl label="Language" placeholder="English, Spanish, etc" name="language" required /> */}
            <FormControl label="Interview style" type="textarea" rows={2} name="interview" placeholder="Do you want to practice technical, behavioral, or a mix of both styles? (200 characters max.)" />
            <UploadResume name="resume" required />
            <Plan />
            <Button className="btn-primary" type="submit" >Next</Button>
            <p className="form-footer">
              <a href="#Privacy">Privacy Policy</a>
              <span>|</span>
              <a href="#Terms">Terms and conditions</a>
            </p>
          </div>
        </form>
      )}
    </HomePageContent>
  );
};

export default HomePage;


// import { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { useUser } from '@clerk/clerk-react';
// import { Button, FormControl, UploadResume } from "../components/formControl";
// import Plan from "../components/Plan";
// import { PAGES } from "../utils/constants";
// import { HomePageContent } from "../components/slyledComponents";
// import { useGlobalContext } from "../utils/globalContext";

// const HomePage = () => {
//   const [showReadyToStart, setShowReadyToStart] = useState(false);
//   const [role, setRole] = useState(''); // role
//   const { user } = useUser();
//   const navigate = useNavigate();
//   const { startInterview, getUserInfos, userInfos, loading } = useGlobalContext();

//   const handleStartInterview = () => {
//     navigate(PAGES.INTERVIEW.path)
//   }
//   const { api } = useGlobalContext();

//   useEffect(() => {
//     getUserInfos();
//   }, [])

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     if (userInfos?.totalRemainingInterviews === 0) {
//       api.post('checkout', { url: window.location.href })
//         .then(({ url }) => {
//           window.location = url;
//         });
//     } else {
//       const formData = new FormData(e.target);
//       setShowReadyToStart(true);
//       const formDataObj = Object.fromEntries(formData.entries());
//       setRole(formDataObj.role); // role
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         const pdfBase64 = reader.result.split(',')[1];
//         startInterview({
//           userId: user.id,
//           duration: +formDataObj.duration,
//           // language: formDataObj.language,
//           role: formDataObj.role,
//           resume: pdfBase64,
//           interviewStyle: formDataObj.interview,
//           extraInformation: '',
//         })
//           .catch((error) => {
//             setShowReadyToStart(false);
//             console.error(error);
//           });
//       };
//       reader.readAsDataURL(formDataObj.resume);
//       const a = new Audio();
//       a.play();
//     }
//   };

//   return (
//     <HomePageContent>
//       {showReadyToStart ? (
//         <div>
//           <h1>You are interviewing for {role} role!</h1>
//           <p className="subtitle">Your AI interviewing copilot is well-versed knows your background and role. Feel free to converse naturally and specify what you'd like to practice.</p>
//           <div className="ready-to-start-actions">
//             <Button onClick={() => setShowReadyToStart(false)} >Return</Button>
//             <Button onClick={handleStartInterview} className="btn-primary">Start</Button>
//           </div>
//         </div>
//       ) : (
//         <form onSubmit={handleSubmit}>
//           <h1>Welcome, {user?.firstName}!</h1>
//           <p className="subtitle">Help us craft your interview experience.</p>
//           <p className="interview-duration">Interview duration</p>
//           <div className="interview-radio">
//             <label><input type="radio" name="duration" value="15" defaultChecked /> 15 min</label>
//             {userInfos?.totalInterviews > 1 ? <label><input type="radio" name="duration" value="30" /> 30 min</label> : null}
//           </div>
//           <div className="form">
//             <p className="form-title">You're interviewing for:</p>
//             <FormControl label="Role" placeholder="Product Designer" name="role" required />
//             {/* <FormControl label="Language" placeholder="English, Spanish, etc" name="language" required /> */}
//             <FormControl label="Interview style" type="textarea" rows={2} name="interview" placeholder="Do you want to practice technical, behavioral, or a mix of both styles? (200 characters max.)" />
//             <UploadResume name="resume" required />
//             <Plan />
//             <Button className="btn-primary" type="submit" >Next</Button>
//             <p className="form-footer">
//               <a href="#Privacy">Privacy Policy</a>
//               <span>|</span>
//               <a href="#Terms">Terms and conditions</a>
//             </p>
//           </div>
//         </form>
//       )}
//     </HomePageContent>
//   );
// };

// export default HomePage;
