import { ErrorModal } from "./modal"

function ErrorMessage({ message, onClose }) {
  return (
    <ErrorModal show={!!message} onClose={onClose}>
      <div className="error-message">
        {message}
      </div>
    </ErrorModal>
  )
}

export default ErrorMessage
