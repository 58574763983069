import styled from "styled-components";
import { useGlobalContext } from './../utils/globalContext';

function Plan() {
  const { api, userInfos, loading } = useGlobalContext();
  const handleClick = () => {
    api.post('checkout', { url: window.location.href })
      .then(({ url }) => {
        window.location = url;
      });
  }
  if (loading) return null;
  return (
    <PlanWrapper onClick={handleClick}>
      <div>
        <b>Your Plan</b>
        <p>{userInfos?.totalRemainingInterviews} interviews remaining</p>
      </div>
      <button type="button">Upgrade</button>
    </PlanWrapper>
  )
}
const PlanWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 12px 16px !important;
  border-radius: 4px;
  background: #000;
  color: #FFF;
  margin-top: 32px !important;
  cursor: pointer;

  & > div {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    text-align: left;
  }

  & > button {
    all: unset;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
`;

export default Plan;
