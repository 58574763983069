export const PAGES = {
  LANDING: {
    path: '/'
  },
  HOME: {
    path: '/'
  },
  INTERVIEW: {
    path: '/interview'
  },
  HISTORY: {
    path: '/history'
  },
  DEBRIEF: {
    path: '/debrief'
  }
}
