import { useEffect, useState } from "react";
import { useGlobalContext } from "../utils/globalContext"
import styled from "styled-components";
import Loader from "../components/loader";

function HistoryPage() {
  const { getHistory } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    setLoading(true);
    getHistory()
      .then((data) => {
        if (Array.isArray(data)) {
          // Assuming all dates are from the same year for sorting purposes.
          const sortedData = data.sort((a, b) => {
            // Constructing date objects for comparison.
            const dateA = new Date(`2024-${a.month}-${a.day}`);
            const dateB = new Date(`2024-${b.month}-${b.day}`);
            return dateB - dateA;
          });
          setList(sortedData);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <HistoryPageWrapper className="container">
      <h1>Interview history</h1>
      {loading ? (
        <Loader style={{ marginTop: 48 }} />
      ) : (
        <table cellSpacing={0}>
          <thead>
            <tr>
              <th>Date</th>
              <th>Role</th>
              <th>Duration</th>
            </tr>
          </thead>
          <tbody>
            {list.length ? list.map((item) => (
              <tr key={item.id} onClick={item?.onClick}>
                <td>{item.month} <br /> {item.day}</td>
                <td>{item.role}</td>
                <td>{item.duration} min.</td>
              </tr>
            )) : (
              <tr>
                <td colSpan={4}>
                  <div className="empty">
                    No data
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </HistoryPageWrapper>
  )
}

const HistoryPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 658px;
  width: 90%;
  h1 {
    text-align: center;
    margin-top: 64px;
  }
  table {
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    margin-top: 48px;
    thead {
      th {
        background: #EEEDF2;
        padding: 12px;
        text-align: left;
      }
    }
    tbody {
      tr {
        margin-top: 1px;
        cursor: pointer;
        td:first-child {
          font-weight: bolder;
          text-align: center;
          .empty {
            font-weight: initial;
            color: #ccc;
          }
        }
        td {
          border-bottom: 1px solid #EEEDF2;
          background-color: #ffffff60;
          padding: 16px;
        }
        &:hover {
          background-color: #EEEDF2;
      }
    }
  }
`;

export default HistoryPage
