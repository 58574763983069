import styled from 'styled-components';
import { FileSelected, Remove, Upload } from './icons';
import { useEffect, useRef, useState } from 'react';

export const FormControl = ({ label, type, name, value, onChange, placeholder, rows, required }) => {
  return (
    <StyledFormControl>
      <label>{label}</label>
      {
        {
          text: <input type={type} name={name} value={value} placeholder={placeholder} onChange={onChange} required={required} />,
          textarea: <textarea rows={rows} name={name} value={value} placeholder={placeholder} onChange={onChange} required={required} />,
        }[type || 'text']
      }
    </StyledFormControl>
  );
};

const StyledFormControl = styled.div`
  border-radius: 4px;
  border: 1px solid #000;
  padding: 8px 12px !important;

  label {
    display: block;
    font-size: 12px;
    font-weight: 600;
  }

  input, textarea {
    width: 100%;
    border: none;
    font-size: 12px;
    line-height: 20px;
    outline: none;
    background-color: transparent;
  }

  &:has(*:focus) {
    border: 1px solid #3659E3;
    & > label {
      color: #3659E3;
    }
  }
`;


export const UploadResume = ({ name }) => {
  const inputRef = useRef(null);
  const [fileError, setFileError] = useState('');
  const [filekey, setFileKey] = useState('file');
  const [, setR] = useState(false);

  useEffect(() => {
    const inputElement = inputRef.current;
    const checkFileSize = (event) => {
      const file = event.target.files[0];
      if (file) {
        const fileSize = file.size / 1024 / 1024;
        if (fileSize > 1) {
          setFileError('File size exceeds 1MB. Please select a smaller file.');
          event.target.value = '';
        } else {
          setFileError('');
        }
      }
      setR(Math.random());
    };

    inputElement.addEventListener('change', checkFileSize);

    return () => {
      inputElement.removeEventListener('change', checkFileSize);
    };
  }, [inputRef.current]);

  const removeFile = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target.value = '';
    setFileKey('key' + Math.random());
    inputRef.current = null;
  }

  const selectFile = () => inputRef.current.click();
  const selected = !!inputRef.current?.files?.length;
  return (
    <>
      <StyledUploadResume onClick={selectFile} className={selected ? 'selected' : ''}>
        <input key={filekey} ref={inputRef} type='file' name={name} accept=".doc,.docx,.pdf,.txt" style={{ display: 'none' }} />
        {selected ? <FileSelected /> : <Upload />}
        <div>
          <label>Upload Resume (Max. 1MB)</label>
          <p>Use pdf, docx, doc and txt</p>
        </div>
        {selected ? (
          <button onClick={removeFile}>
            <Remove />
          </button>
        ) : null}
      </StyledUploadResume>
      {fileError ? <small className='error-message'>{fileError}</small> : null}
    </>

  )
}

const StyledUploadResume = styled.div`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='4' ry='4' stroke='%23333' stroke-width='1' stroke-dasharray='10%2c10' stroke-dashoffset='12' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 4px;
  padding: 8px 12px !important;
  margin: 1px 0;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;

  & > button {
    all: unset;
    cursor: pointer;
  }

  div {
    flex: 1;
    label {
      display: block;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;
    }

    p {
      color: #A9A8B3;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &:hover, &:hover > p {
      color: #3659E3;
    }
  }

  &.selected {
    border: 1px solid #000;
    background-image: none;
    margin: 0;
  }
`;

export const Button = styled.button`
  all: unset;
  display: flex;
  padding: 12px 40px !important;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid transparent;
  &.btn-primary {
    border: 2px solid #000;
  }
  &:hover {
    color: #3659E3;
    border-color: #3659E3;
  }
`;