import React, { createContext, useCallback, useContext, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PAGES } from './constants';
import ErrorMessage from '../components/ErrorMessage';

const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

export const useAPI = () => {
  const { api } = useGlobalContext();
  return api;
}

const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '/';
const getBackendEndPoint = (path) => `${REACT_APP_BACKEND_URL.replace(/\/+$/, '')}${path.startsWith('/') ? path : '/' + path}`;
const useQueryParams = () => {
  return new URLSearchParams(useLocation().search);
};

export const GlobalProvider = ({ children }) => {
  const [thread, setThread] = useState([]);
  const [interviewInfos, setInterviewInfos] = useState({});
  const [endedInterview, setEndedInterview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [debriefItem, setDebriefItem] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userInfos, setUserInfos] = useState(null);
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const qParams = useQueryParams();

  const getUserInfos = () => {
    setLoading(true);

    return api.post('getUserInfo', { checkpay: qParams.get('success') || null })
      .then((response) => {
        if (qParams.get('success')) {
          navigate(window.location.pathname);
        }
        setUserInfos(response);
        return response;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const startInterview = (data) => {
    setLoading(true);
    setInterviewInfos(data);
    return api.post('startInterview', data)
      .then((response) => {
        setThread([response])
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const sendMessage = useCallback((data) => api.post('sendMessage', data).then((response) => setThread((list) => ([...list, response]))), []);

  const fetchWithToken = async (path, options = {}) => {
    const token = await getToken();
    const headersWithToken = {
      ...options.headers,
      Authorization: `Bearer ${token}`,
      mode: 'cors',
    };
    const TRY = 3;
    return new Promise(async (resolve, reject) => {
      for (let i = 1; i <= TRY; i++) {
        try {
          let result = await fetch(getBackendEndPoint(path), { ...options, headers: headersWithToken });
          if (result.ok || (result.status >= 200 && result.status < 400)) {
            resolve(result);
            return;
          
          }
        } catch (error) { }
      }
      setErrorMessage("Something went wrong while processing your request. Please try again.");
      reject("Something went wrong while processing your request. Please try again.");
    });
  };

  const api = {
    get: async (path) => fetchWithToken(path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(result => result.json()),
    post: async (path, data) => {
      const isFormData = data instanceof FormData;
      const fetchOptions = {
        method: 'POST',
        headers: isFormData ? {} : { 'Content-Type': 'application/json' },
        body: isFormData ? data : JSON.stringify(data),
      };
      return fetchWithToken(path, fetchOptions).then(result => result.json());
    }
  };


  const getHistory = () => api.get('getHistory').then(data => (data || []).map(initHistoryItem))

  const endInterview = (endedBy) => api
    .post('endInterview', { endedBy, threadId: thread.length ? thread[0].threadId : null })
    .then(initHistoryItem)
    .then(item => setEndedInterview(item))

  const initHistoryItem = (item) => {
    item.onClick = () => {
      setDebriefItem(item);
      navigate(PAGES.DEBRIEF.path);
      if (!item.debrief) {
        setDebriefItem({ ...item, loading: true });
        api.post('getDebrief', { id: item.id })
          .then((data) => {
            item.debrief = data;
            setDebriefItem({ ...item, loading: false });
          });
      }
    }
    return item;
  }

  return (
    <GlobalContext.Provider value={{ api, startInterview, sendMessage, thread, loading, endInterview, getHistory, interviewInfos, debriefItem, endedInterview, userInfos, getUserInfos }}>
      {children}
      <ErrorMessage message={errorMessage} onClose={() => setErrorMessage(null)} />
    </GlobalContext.Provider>
  );
};
