import React from 'react';
import styled from 'styled-components';

const DotFlashing = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #000;
  color: #000;
  animation: dotFlashing 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before, &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -20px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #000;
    color: #000;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 20px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #000;
    color: #000;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dotFlashing {
    0% {
      background-color: #000;
    }
    50%, 100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }
`;

const Loader = (props) => {
  return (
    <DotFlashing {...props} />
  );
};

export default Loader;
