import styled from "styled-components";
import { Button } from "./formControl";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background-color: #fff;
  padding: 0 20px 20px 20px;
  border-radius: 8px;
  width: 440px;
  position: relative;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
`;

const ModalTitle = styled.h2`
  text-align: center;
  flex: 1;
`;

const ModalBody = styled.div`
  margin-bottom: 30px;
  text-align: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
`;


export function ErrorModal({ show, onClose, children }) {
  if (!show) return null;
  return (
    <ModalContainer>
      <ModalContent>
        <ModalBody>
          {children}
        </ModalBody>
        <ButtonGroup>
          <Button onClick={onClose}>Close</Button>
        </ButtonGroup>
      </ModalContent>
    </ModalContainer>
  );
}

export function EndInterviewModal({ show, onClose, minutesLeft, onConfirm }) {
  if (!show) return null;
  return (
    <ModalContainer>
      <ModalContent>
        <ModalHeader>
          <ModalTitle>End Interview?</ModalTitle>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>
        <ModalBody>
          You have {minutesLeft} minutes left. Would you like to proceed and <br /> review the summary of your interview performance? <br />
          This will count as one completed interview.
        </ModalBody>
        <ButtonGroup>
          <Button onClick={onClose}>Cancel</Button>
          <Button className="btn-primary" onClick={onConfirm}>End</Button>
        </ButtonGroup>
      </ModalContent>
    </ModalContainer>
  );
}