// import { useCallback, useState } from "react";
// import { RecordOff, RecordOn, RecordSend, StopRecord } from "./icons";
// import { INTERVIEW_SCREENS } from "../pages/Interview";

// export const RECORDING_STATUS = {
//   START: 'START',
//   END: 'END',
//   SEND: 'SEND',
// }

// const RecordAudio = ({ screen, onStatusChange }) => {
//   const [audioBlob, setAudioBlob] = useState(null);
//   const [mediaRecorder, setMediaRecorder] = useState(null);

//   const startRecording = useCallback(() => {
//     if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
//       navigator.mediaDevices.getUserMedia({ audio: true })
//         .then(stream => {
//           const mimeType = ['audio/webm', 'audio/mp4'].find(t => MediaRecorder.isTypeSupported(t));
         
//           const mediaRecorder = new MediaRecorder(stream, { mimeType });
//           setMediaRecorder(mediaRecorder);
//           let audioData = [];
  
//           mediaRecorder.ondataavailable = event => {
//             audioData.push(event.data);
//           };
  
//           mediaRecorder.onstop = () => {
//             const audioBlob = new Blob(audioData, { type: mimeType });
//             const reader = new FileReader();
//             reader.onloadend = () => {
//               const base64AudioMessage = reader.result.toString();
//               setAudioBlob(base64AudioMessage);
//               onStatusChange(RECORDING_STATUS.END, base64AudioMessage);
//             };
//             reader.readAsDataURL(audioBlob);
//             audioData = [];
//           };
  
//           mediaRecorder.start();
//           onStatusChange(RECORDING_STATUS.START);
//         })
//         .catch((error) => {
//           console.error("Error accessing the microphone", error);
//         });
//     } else {
//       alert("Your browser does not support audio recording");
//     }
//   }, [onStatusChange]);

//   const stopRecording = () => {
//     if (mediaRecorder) {
//       mediaRecorder.stop();
//     }
//   };

//   switch (screen) {
//     case INTERVIEW_SCREENS.WAITING_FOR_USER_ANSWER:
//       return (
//         <button style={{ all: 'unset' }} onClick={startRecording}>
//           <RecordOn />
//         </button>
//       )
//     case INTERVIEW_SCREENS.USER_AUDIO_RECORDING:
//       return (
//         <button style={{ all: 'unset' }} onClick={stopRecording}>
//           <StopRecord />
//         </button>
//       )
//     case INTERVIEW_SCREENS.USER_AUDIO_SEND:
//       return (
//         <button style={{ all: 'unset' }} onClick={() => onStatusChange(RECORDING_STATUS.SEND, audioBlob)}>
//           <RecordSend />
//         </button>
//       )
//     default:
//       return <RecordOff />;
//   }
// };

// export default RecordAudio;

import { useCallback, useState } from "react";
import RecordRTC from "recordrtc";
import { RecordOff, RecordOn, RecordSend, StopRecord } from "./icons";
import { INTERVIEW_SCREENS } from "../pages/Interview";

export const RECORDING_STATUS = {
  START: 'START',
  END: 'END',
  SEND: 'SEND',
};

const RecordAudio = ({ screen, onStatusChange }) => {
  const [audioBlob, setAudioBlob] = useState(null);
  const [recorder, setRecorder] = useState(null);

  const startRecording = useCallback(() => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const recorder = new RecordRTC(stream, {
          type: 'audio',
          mimeType: 'audio/webm', // Using a more universally compatible format
          recorderType: RecordRTC.StereoAudioRecorder, // Opt for a recorder type that is widely supported
        });

        recorder.startRecording();
        setRecorder(recorder);
        onStatusChange(RECORDING_STATUS.START);
      })
      .catch((error) => {
        console.error("Error accessing the microphone", error);
      });
  }, [onStatusChange]);

  const stopRecording = () => {
    if (recorder) {
      recorder.stopRecording(() => {
        const blob = recorder.getBlob();
        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64AudioMessage = reader.result.toString();
          setAudioBlob(base64AudioMessage);
          onStatusChange(RECORDING_STATUS.END, base64AudioMessage);
        };
      });
    }
  };

  switch (screen) {
    case INTERVIEW_SCREENS.WAITING_FOR_USER_ANSWER:
      return (
        <button style={{ all: 'unset' }} onClick={startRecording}>
          <RecordOn />
        </button>
      );
    case INTERVIEW_SCREENS.USER_AUDIO_RECORDING:
      return (
        <button style={{ all: 'unset' }} onClick={stopRecording}>
          <StopRecord />
        </button>
      );
    case INTERVIEW_SCREENS.USER_AUDIO_SEND:
      return (
        <button style={{ all: 'unset' }} onClick={() => onStatusChange(RECORDING_STATUS.SEND, audioBlob)}>
          <RecordSend />
        </button>
      );
    default:
      return <RecordOff />;
  }
};

export default RecordAudio;
