import React from 'react';
import Header from '../components/Header';
import { Outlet } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import Loader from '../components/loader';

const Layout = () => {
  const { isLoaded } = useUser();
  if (!isLoaded) {
    return (
      <div className='loading-user-data'>
        <Loader />
      </div>
    );
  }
  return (
    <>
      <Header />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
