import { useEffect } from 'react';

function AudioPlayer({ audioBase64, onEnd, onStart }) {

    useEffect(() => {
        let audio = new Audio(`data:audio/mp3;base64,${audioBase64}`);
        audio.load();
        audio.addEventListener('canplaythrough', () => {
            if(audio) {
                audio.play().then(() => {
                    if (onStart) onStart();
                }).catch(error => {
                    console.log(error);
                });
            }
        });
        audio.addEventListener('ended', () => {
            if (onEnd) onEnd();
        });
        return () => {
            audio.pause();
            audio.currentTime = 0;
            audio = null;
        };
    }, [audioBase64]);

    return null;
}

export default AudioPlayer;
